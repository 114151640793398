<script setup lang="ts">
  interface Props {
    href?: string;
    size?: 'sm' | 'md' | 'lg' | null;
    block?: boolean;
    styleName?: 'outline' | 'primary' | 'inverted' | 'muted';
    className?: string;
    [x: string]: any;
  }

  withDefaults(defineProps<Props>(), {
    size: 'md',
    styleName: 'primary'
  });

  const sizes = {
    lg: 'px-5 py-2.5 text-lg',
    md: 'px-4 py-2 text-md',
    sm: 'px-2 py-1 text-sm',
    null: ''
  };

  const styles = {
    outline:  'text-black',
    primary:  'text-white',
    inverted: 'text-black',
    muted:    'text-black',
    title:    'text-slate-600 hover:text-slate-900'
  };
</script>

<template>
  <NuxtLink
    v-if="href"
    :href="href"
    :class="[
      block && 'w-full',
      sizes[size],
      styles[styleName],
      className
    ]">
    <slot />
  </NuxtLink>
  <button
    v-else
    :class="[
      block && 'w-full',
      sizes[size],
      styles[styleName],
      className
    ]">
    <slot />
  </button>
</template>
