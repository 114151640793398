<template>
  <div class="content px-4 sm:px-16">
    <LandingNavbar class="top-0 border-b-2"></LandingNavbar>
    <main class="flex flex-col justify-center">
      <slot/>
    </main>
    <LandingFooter/>
  </div>
  <div class="resolution-notice bg-red-200 text-xl text-gray-900 font-bold">
    Toto rozlíšenie nie je podporované.
  </div>
</template>

<style lang="scss">
  .content {
    @apply flex flex-col justify-between container bg-white;
    @media only screen and ((max-height: 250px) or (max-width: 250px)) {
      @apply hidden;
    }
  }

  .resolution-notice {
    @apply hidden;
    @media only screen and ((max-height: 250px) or (max-width: 250px)) {
      @apply flex items-center text-center;
    }
  }
</style>
